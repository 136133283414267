<template>
  <div class="itens">
    <v-item-group v-model="selected" mandatory class="d-flex">
      <template v-for="(item, index) in items">
        <v-item v-slot="{ active, toggle }" :value="item.vendor">
          <div
            @click="toggle, $emit('input', item.vendor)"
            class="card"
            :style="active ? 'border-color: #624DE3' : 'border-color: #E0E0E0'"
          >
            <div class="card-action">
              <div class="icon">
                <v-avatar size="48px">
                  <img
                    v-if="item.vendor === 'efi'"
                    size="38px"
                    src="https://imagensfree.com.br/wp-content/uploads/2022/01/icone-pix.svg"
                  />

                  <v-icon
                    v-if="item.vendor !== 'efi'"
                    size="48px"
                    color="black"
                  >
                    mdi-credit-card
                  </v-icon>
                </v-avatar>
              </div>
               <div class="select">
                <v-icon v-if="!active"> mdi-radiobox-blank </v-icon>

                <v-icon color="primary" v-if="active">
                  mdi-radiobox-marked
                </v-icon>
              </div>
            </div>
            <div class="card-text">
              {{ item.name }}
            </div>
          </div>
         </v-item>
      </template>
    </v-item-group>
  </div>
</template>

<script>
export default {
  props: {
    value: {},
  },
  data() {
    return {
      selected: "stripe",

      items: [
        {
          vendor: "stripe",
          name: "Cartão de Crédito",
        },
        { vendor: "efi", name: "Pix" },
      ],
    };
  },

  watch: {
    value(value) {
      this.selected = value;
    },
  },

  created() {
    this.selected = this.value;
  },

  methods: {
    getClass(index) {
      return {
        "ml-4": index === this.items.length - 1,
      };
    },
  },
};
</script>

<style lang="scss" >
/* desktop */
@media (min-width: 960px) {
  .itens {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .card {
    box-sizing: border-box;
    height: 130px;
    background: #ffffff;
    border: 1.5px solid #ebebf5;
    border-radius: 32px;
    width: 300px;
    margin: 24px;
  }
  .card-icon {
    max-width: 48px;
  }
  .card-action {
    margin-top: 24px;
    margin-left: 24px;
    margin-right: 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 48px;
  }
  .card-text {
    margin-top: 12px;
    margin-left: 24px;
    margin-right: 24px;
    height: 24px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;

    align-items: center;
    letter-spacing: 0.5px;
    color: #4e4b59;
  }
}

/* mobile*/
@media (max-width: 960px) {
  .v-item-group {
    display: flex;
    flex-direction: column;
  }
  .card {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    gap: 16px;
    width: 100%;
    height: 120px;
    background: #ffffff;
    border: 1.5px solid #ebebf5;
    border-radius: 24px;
    flex: none;
    margin-bottom: 16px;
    margin-top: 16px;
  }
  .card-icon {
    max-width: 24px;
  }
  .card-action {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    gap: 16px;
    height: 32px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
  }
  .card-text {
    width: 100%;
    height: 40px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: 0.4px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
  }
}
</style>