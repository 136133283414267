<template>
  <div>
    <template v-if="!isIncomplete">
      <template v-if="canSubscribe">
        <div v-show="step == 1">
          <v-row class="mb-2" v-if="showAlert">
            <v-col>
              <v-alert type="info">
                Seu período de teste terminou. Assine já e economize tempo com o
                Psicoplanner!
              </v-alert>
            </v-col>
          </v-row>
          <h2>Planos de acordo com a sua necessidade...</h2>
          <PlanSelection @input="handlePlanSelection($event)" />
        </div>
        <div id="billing-information-form" v-show="step == 2">
          <v-card outlined>
            <v-card-title>
              <app-title>Informe os dados para assinatura...</app-title>
            </v-card-title>
            <v-card-text>
              <BaseBillingInformationForm v-model="form" />
            </v-card-text>
            <v-card-actions>
              <app-back-btn @click="step--" text class="mr-2">
                Voltar
              </app-back-btn>
              <app-btn @click="storeBillingInformation()" color="primary">
                Proximo
              </app-btn>
            </v-card-actions>
          </v-card>
        </div>
        <div id="vendor-selection" v-show="step == 3">
          <v-card outlined>
            <v-card-title>
              <app-title>Selecione a forma de pagamento...</app-title>
            </v-card-title>
            <v-card-text>
              <VendorSelection v-model="form.vendor" />
            </v-card-text>
            <v-card-actions>
              <app-back-btn @click="step--" text class="mr-2">
                Voltar
              </app-back-btn>
              <app-btn @click="getPayment()" color="primary"> Proximo </app-btn>
            </v-card-actions>
          </v-card>
        </div>
        <div v-show="step == 4">
          <div
            class="d-flex justify-center text-center mt-15"
            style="width: 100%"
          >
            <template v-if="isEfi">
              <AppInvoice :charge="charge" />
            </template>
            <template v-if="!isEfi">
              <v-progress-circular
                class="mb-8"
                color="primary"
                indeterminate
                size="32"
              />
              <br />
              <h2>Redirecionando para o pagamento...</h2>
            </template>
          </div>
        </div>
      </template>
      <template v-if="!canSubscribe">
        <div class="text-center">
          <div class="my-6">Você já possui uma assinatura Ativa!</div>
          <div class="my-6">
            <v-btn color="primary" @click="$router.push('/')()"> Voltar </v-btn>
          </div>
        </div>
      </template>
    </template>
    <template v-if="isIncomplete">
      <v-row class="d-flex justify-center">
        <AppInvoiceDialog @update="processRedirect()" ref="AppInvoiceDialog" />
      </v-row>
    </template>
  </div>
</template>

<script>
import AppInvoice from "@/components/subscription/sections/AppInvoice.vue";
import PlanSelection from "@/components/subscription/sections/PlanSelectionV2.vue";
import VendorSelection from "@/components/subscription/sections/VendorSelection.vue";
import AppInvoiceDialog from "@/components/subscription/sections/AppInvoiceDialog.vue";
import BaseBillingInformationForm from "@/components/subscription/forms/BaseBillingInformationForm.vue";
export default {
  components: {
    AppInvoice,
    PlanSelection,
    VendorSelection,
    AppInvoiceDialog,
    BaseBillingInformationForm,
  },

  data() {
    return {
      step: 1,
      charge: {},
      form: {
        plan: {},
        billingInformation: {},
      },
    };
  },

  created() {
    this.refreshUserData();
  },
  mounted() {
    if (this.isIncomplete) {
      this.$refs.AppInvoiceDialog.open();
    }
  },

  computed: {
    user() {
      return this.$store.state.auth.user;
    },

    isOnTrial(state, getters) {
      if (getters.hasSubscription) {
        return false;
      }

      return true;
    },

    isOnTrial() {
      return this.$store.getters["subscription/isOnTrial"];
    },

    isTrialEnded() {
      return this.$store.getters["subscription/isTrialEnded"];
    },

    canSubscribe() {
      return this.$store.getters["subscription/canSubscribe"];
    },

    showAlert() {
      return this.isOnTrial && this.isTrialEnded;
    },
    isEfi() {
      return this.form.vendor === "efi";
    },

    isIncomplete() {
      return this.$store.getters["subscription/incomplete"];
    },
  },

  methods: {
    refreshUserData() {
      this.$auth
        .getLoginData()
        .then((response) => {
          this.$loading.finish();
          this.processSaved();
        })
        .catch((response) => {
          this.$loading.finish();
        });
    },

    handlePlanSelection(plan) {
      this.form.plan = plan;
      this.step++;
    },

    storeBillingInformation() {
      this.form.vendor = "stripe";
      this.$loading.start();

      this.$http
        .store("subscription/billing-information", this.form)
        .then((response) => {
          this.form.billingInformation = response;
          this.step++;
          this.$loading.finish();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    getPayment(response) {
      if (!this.isEfi) {
        this.getCheckoutUrl();
      }

      if (this.isEfi) {
        this.createEfiSubscription();
      }
    },

    async createEfiSubscription() {
      this.$loading.start();
      await this.$http
        .store("subscription/efi/checkout", this.form)
        .then((response) => {
          this.charge = response.charge;
          this.checkEfiSubscription(response.subscription.id);
          this.$loading.finish();
          this.step++;
        })
        .catch((error) => this.$loading.finish());
    },
    getCheckoutUrl() {
      this.$http.store("/subscription/checkout", this.form).then((response) => {
        location.href = response;
      });
    },

    async checkEfiSubscription(subscription_id) {
      await this.$http
        .show("subscription/efi/checkout", subscription_id)
        .then((response) => {
          var isPaid = response.subscriptionIsPaid;

          if (isPaid) this.processRedirect();

          if (!isPaid) {
            setTimeout(() => this.checkEfiSubscription(subscription_id), 5000);
          }
        })
        .catch((error) => console.log(error));
    },

    processRedirect() {
      this.$auth
        .getLoginData()
        .then((response) => this.$router.push("/dashboard"))
        .catch((error) => console.log(error));
    },
  },
};
</script>

<style lang="scss">
// mobile
@media (max-width: 960px) {
  #billing-information-form {
    .theme--light.v-sheet--outlined {
      border: 0px !important;
    }
    .v-card__title {
      padding: 0px !important;
    }
    .v-card__text {
      padding: 0px !important;
    }
    .v-card__actions {
      padding-left: 0px !important;
      padding-right: 0px !important;
      display: flex;
      justify-content: space-between;
      padding-top: 40px !important;
    }
  }
  #vendor-selection {
    .theme--light.v-sheet--outlined {
      border: 0px !important;
    }
    .v-card__title {
      padding: 0px !important;
    }
    .v-card__text {
      padding: 0px !important;
    }
    .v-card__actions {
      padding-left: 0px !important;
      padding-right: 0px !important;
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>
