<template>
  <div>
    <v-row class="mt-5">
      <v-col>
        <v-alert class="mb-0" color="grey lighten-3" style="width: 100%">
          <div
            class="d-flex flex-column align-center justify-md-space-between flex-md-row"
          >
            <div>
              Pensando em ficar por um tempo?
              <br />
              <b>Assine o plano anual economize duas parcelas!</b>
            </div>
            <!-- <div class="mt-5 mt-md-0">
             
            </div> -->
          </div>
        </v-alert>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-center">
        <!-- <v-chip-group
              @change="select()"
              mandatory
              v-model="searchParams.status"
            >
              <v-chip small color="primary" filter :value="'active'">
                Ativos
              </v-chip>
              <v-chip small outlined filter :value="'inactive'">
                Inativos
              </v-chip>
            </v-chip-group> -->
        <v-chip-group mandatory v-model="plansDays">
          <v-chip outlined filter color="primary" :value="30">
            Assinatura Mensal
          </v-chip>
          <v-chip outlined filter :value="365"> Assinatura Anual </v-chip>
        </v-chip-group>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center">
      <template v-for="(plan, index) in plansByDays">
        <v-col cols="12" md="3" :key="index">
          <v-card outlined>
            <v-card-text class="pa-6">
              <v-row>
                <v-col>
                  <h3 class="secondary--text mb-4">
                    {{ plan.name }}
                  </h3>
                  <template v-if="plan.value > 0">
                    <h1 class="mb-4 secondary--text d-inline">
                      R$ {{ $format.decimal(plan.value, 2) }}
                    </h1>
                    / {{ periodString(plan.days) }}
                  </template>
                  <template v-else>
                    <h1 class="mb-4 secondary--text d-inline">Sob Consulta</h1>
                  </template>
                  <div class="mt-2">
                    {{ getDescription(plan).target }}
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <div class="mb-4">
                    <app-icon color="success" class="mr-2">
                      check_circle
                    </app-icon>
                    <span class="secondary--text">
                      {{ getProfessionalLimitString(plan) }}
                    </span>
                  </div>
                  <div class="mb-4">
                    <template v-if="plan.attendant_limit > 0">
                      <app-icon color="success" class="mr-2">
                        check_circle
                      </app-icon>
                    </template>
                    <template v-else>
                      <app-icon class="mr-2">cancel</app-icon>
                    </template>
                    <span class="secondary--text">
                      {{ getAttendantLimitString(plan) }}
                    </span>
                  </div>
                  <div class="mb-4">
                    <app-icon color="success" class="mr-2">
                      check_circle
                    </app-icon>
                    <span class="secondary--text">Lembretes ilimitados</span>
                  </div>
                  <div class="mb-4">
                    <app-icon color="success" class="mr-2">
                      check_circle
                    </app-icon>
                    <span class="secondary--text">Clientes ilimitados</span>
                  </div>
                  <div class="mb-4">
                    <app-icon color="success" class="mr-2">
                      check_circle
                    </app-icon>
                    <span class="secondary--text">1GB Upload de arquivos</span>
                  </div>

                  <div class="mb-4">
                    <app-icon color="success" class="mr-2">
                      check_circle
                    </app-icon>
                    <span class="secondary--text">Sala de Vídeo</span>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions class="default-card">
              <template v-if="plan.value == 0">
                <v-btn color="primary" block @click="redirectToWhatsapp()">
                  <v-icon left>mdi-whatsapp</v-icon>
                  Fale conosco
                </v-btn>
              </template>
              <template v-else>
                <app-btn @click="selectPlan(plan)" block color="primary">
                  Escolher plano
                </app-btn>
              </template>
            </v-card-actions>
          </v-card>
        </v-col>
      </template>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      plansDays: 30,
      plans: [],
    };
  },
  mounted() {
    this.select();
  },

  computed: {
    company() {
      return this.$store.state.auth.company;
    },

    companyType() {
      return this.$store.state.auth.company.type;
    },

    params() {
      return !this.$store.state.auth.user.is_admin
        ? { status: "active" }
        : null;
    },

    plansByDays() {
      return this.plans.filter((plan) => plan.days == this.plansDays);
    },
  },

  methods: {
    selectPlan(plan) {
      this.$emit("input", plan);
    },

    select() {
      this.$http.index("subscription/plans", this.params).then((response) => {
        this.setPlans(response.plans, this.companyType);
      });
    },

    setPlans(plans, type) {
      this.plans = plans.filter((plan) => {
        return plan.vendor === "stripe";
      });
    },

    redirectToWhatsapp() {
      let link = `https://api.whatsapp.com/send?phone=+${process.env.VUE_APP_SUPPORT_WHATSAPP}`;

      let msg = `Olá, gostaria de saber mais sobre os planos para clinica`;

      window.open(`${link}&text=${msg}`, "_blank");
    },

    getCents(value) {
      return "," + value.toString().slice(-2);
    },

    getProfessionalLimitString(plan) {
      if (plan.professional_limit == 1) {
        return "Para 1 profissional";
      }

      if (plan.professional_limit == "99") {
        return "Sob demanda";
      }

      if (plan.professional_limit > 1) {
        return "Para até " + plan.professional_limit + " profissionais";
      }
    },

    getAttendantLimitString(plan) {
      if (plan.attendant_limit == 0) {
        return "Cadastro de atendentes";
      }

      if (plan.attendant_limit == 1) {
        return "Cadastro de atendente";
      }

      if (plan.attendant_limit > 1) {
        return "Múltiplos atendentes";
      }
    },

    getDescription(plan) {
      return JSON.parse(plan.description);
    },

    periodString(days) {
      return days == 30 ? "Mensal" : "Anual";
    },
  },
};
</script>

<style lang="sass" scoped>
.default-card
  background-color: white
</style>
